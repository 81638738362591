import service from '@/until/api'

// 获取用户反馈
export function _GetFeedback(data) {
  return service({
    url: '/system-nacos/userWebFeedBack/findAllPage',
    method: 'GET',
    params: data
  })
}
// 删除反馈
export function _DeletedFeedback(data) {
  return service({
    url: '/system-nacos/userWebFeedBack/deleteUserFeedbackWeb',
    method: 'POST',
    data,
    headers: { 'content-type': 'multipart/form-data' }
  })
}
// 添加反馈
export function _AddFeedback(data) {
  return service({
    url: '/system-nacos/userWebFeedBack/addUserFeedbackWeb',
    method: 'POST',
    data,
    headers: { 'content-type': 'multipart/form-data' }
  })
}
// 编辑反馈
export function _EditFeedback(data) {
  return service({
    url: '/system-nacos/userWebFeedBack/editUserFeedbackWeb',
    method: 'POST',
    data,
    headers: { 'content-type': 'multipart/form-data' }
  })
}
